export enum ChargeBeePaymentType {
  DIRECT_DEBIT = 'direct_debit',
  CREDIT_CARD = 'card',
}

export interface ChargeBeePaymentMethod {
  bank_name: string;
  brand: string;
  expiry_month: string;
  expiry_year: string;
  last4: string;
  powered_by: string;
  status: string;
  type: string;
}

export enum ChargebeePortalSections {
  SUBSCRIPTION_DETAILS = 'sub_details',
  SUBSCRIPTION_CANCELLATION = 'sub_cancel',
  EDIT_SUBSCRIPTION = 'edit_subscription',
  VIEW_SCHEDULED_CHANGES = 'scheduled_changes',
  ACCOUNT_DETAILS = 'account_details',
  EDIT_ACCOUNT_DETAILS = 'portal_edit_account',
  ADDRES = 'portal_address',
  EDIT_BILLING_ADDRESS = 'portal_edit_billing_address',
  EDIT_SHIPPING_ADDRESS = 'portal_edit_shipping_address',
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS = 'portal_edit_subscription_cf',
  PAYMENT_SOURCES = 'portal_payment_methods',
  ADD_PAYMENT_SOURCE = 'portal_add_payment_method',
  EDIT_PAYMENT_SOURCE = 'portal_edit_payment_method',
  VIEW_PAYMENT_SOURCE = 'portal_view_payment_method',
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = 'portal_choose_payment_method',
  BILLING_HISTORY = 'portal_billing_history',
}

export interface ChargebeePortalResponse {
  portal_session: {
    access_url: string;
    created_at: number;
    customer_id: string;
    expires_at: number;
    id: string;
    linked_customers: ChargebeeLinkedCustomers[];
    object: string;
    redirect_url: string;
    status: string;
    token: string;
  };
}

export interface ChargebeeLinkedCustomers {
  customer_id: string;
  email: string;
  has_active_subscription: boolean;
  has_billing_address: boolean;
  has_payment_method: boolean;
  object: string;
}

export interface ChargebeeHostedPageResponse {
  hosted_page: {
    created_at: number;
    embed: boolean;
    expires_at: number;
    id: string;
    object: string;
    resource_version: number;
    state: string;
    type: string;
    updated_at: number;
    url: string;
  };
}

export interface VatResponse {
  status: VatResponseStatus;
  message: string;
}

export enum VatResponseStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNDETERMINED = 'UNDETERMINED',
}
