import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiGateway } from '../../api/ApiGateway.service';
import { ChargebeePortalResponse } from './billing.model';

@Injectable({
  providedIn: 'root',
})
export class BillingApi {
  private endpoint = 'billing/';

  public constructor(private gateway: ApiGateway) {}

  public getPaymentMethods() {
    return this.gateway.get(this.endpoint + 'payment_method');
  }

  public setupChargebeePortalSession(): Observable<ChargebeePortalResponse> {
    return this.gateway.post('chargebee/portal_session', undefined, undefined);
  }
}
