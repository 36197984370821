import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BillingApi } from './billing.api';
import { ChargeBeePaymentMethod, ChargebeePortalResponse } from './billing.model';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public billingUpdate$: Subject<number> = new Subject<number>();

  //No chargebee types available
  private chargeBeeInstance: any;
  private chargebeePortalSession: any;

  public constructor(private api: BillingApi) {}

  //No chargebee types available
  public getChargeBeeInstance(): any {
    if (this.chargeBeeInstance) {
      return this.chargeBeeInstance;
    }
    this.chargeBeeInstance = window['Chargebee'].init({
      site: environment.chargeBeeSite,
    });

    return this.chargeBeeInstance;
  }

  public getChargeBeePaymentMethod(): Observable<ChargeBeePaymentMethod> {
    return this.api.getPaymentMethods();
  }

  //No chargebee types available
  public initChargebeePortalSession() {
    this.getChargeBeeInstance();
    this.chargebeePortalSession = this.chargeBeeInstance.createChargebeePortal();
    this.chargeBeeInstance.setPortalSession(
      () =>
        new Promise((resolve) => {
          this.api.setupChargebeePortalSession().subscribe((response: ChargebeePortalResponse) => {
            resolve(response.portal_session);
          });
        }),
    );

    return this.chargebeePortalSession;
  }
}
